.react-toggle--checked .react-toggle-track {
  background-color: var(--brand01);
}

.react-toggle--checked .react-toggle-thumb {
  border-color: var(--brand01);
}

.react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: var(--ui06);
}

.react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: var(--brand01);
}

.react-toggle-track {
  background-color: var(--ui05);
}

.react-toggle-thumb {
  border: 1px solid var(--ui05);
}
