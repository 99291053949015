.intl-tel-input {
  margin-top: 0.8rem;
  width: 100%;
}
.intl-tel-input.allow-dropdown.separate-dial-code .selected-flag {
  display: flex;
}
.intl-tel-input.allow-dropdown.separate-dial-code .selected-dial-code {
  padding-left: 8px;
}
.intl-tel-input input {
  border: 1px solid var(--ui04);
  border-radius: 0.4rem;
  box-sizing: border-box;
  height: 4rem;
  width: 100%;
  color: var(--text02);
  font-family: 'Work Sans', sans-serif;
  font-size: 16px;
  line-height: 1.7;
}
@media (min-width: 992px) {
  .intl-tel-input input {
    font-size: 14px;
  }
}

.intl-tel-input input.errorState {
  border: 1px solid var(--support_error);
}

.intl-tel-input input:focus {
  border: 1px solid var(--brand01);
}

.intl-tel-input .selected-flag {
  background-color: var(--ui02);
  border-radius: 0.4rem 0 0 0.4rem;
}
.intl-tel-input input:disabled {
  color: var(--text03);
}
