.react-resizable {
  position: relative;
}
.react-resizable-handle {
  position: absolute;
  width: 100%;
  height: 12px;
  background-repeat: no-repeat;
  background-origin: content-box;
  box-sizing: border-box;
  border-top: 0.5px solid #d3d4d8;
  background-color: #ffffff;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjgiIGhlaWdodD0iNCIgdmlld0JveD0iMCAwIDI4IDQiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxyZWN0IHdpZHRoPSIyOCIgaGVpZ2h0PSI0IiByeD0iMiIgZmlsbD0iI0QzRDREOCIvPgo8L3N2Zz4K");
  background-position: center center;
}
.react-resizable-handle-s {
  bottom: 0;
  cursor: row-resize;
}

@media (max-width: 991px) {
  .react-resizable-handle {
    display: none;
  }
}
